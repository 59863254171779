$(document).ready(function() {
  $('a[href=#]').bind("click", function (e) {
    e.preventDefault();
  });
  $('a[href*=#].scroll').bind("click", function (e) {
    var top = $($(this).attr('href')).offset().top;
    e.preventDefault();
    $('html, body').stop().animate({scrollTop: top}, 1000);
  });

  var header_nav = $('#header-nav');
  var footer_nav = $('#footer-nav');
  header_nav.on('click', 'a:not([data-toggle="dropdown"])', function () {
    header_nav.collapse('hide');
  });
  footer_nav.on('click', 'a:not([data-toggle="dropdown"])', function () {
    footer_nav.collapse('hide');
  });

  new WOW().init();

  Chart.defaults.bluredDoughnut = Chart.defaults.doughnut;

  var draw = Chart.controllers.doughnut.prototype.draw;
  Chart.controllers.bluredDoughnut = Chart.controllers.doughnut.extend({
    draw: function() {
      draw.apply(this, arguments);
      var ctx = this.chart.chart.ctx;
      var _fill = ctx.fill;
      ctx.fill = function() {
        ctx.save();
        ctx.shadowColor = ctx.fillStyle;
        ctx.shadowBlur = 14;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 6;
        _fill.apply(this, arguments)
        ctx.restore();
      }
    }
  });

  // var ctx_chart = document.getElementById("economy-chart").getContext("2d");
  // var economyChart = new Chart(ctx_chart,{
  //   type: 'doughnut',
  //   data: {
  //     labels: ["Early investors of platform", "Team", "Advisers", "Early partners", "Bounty", "Referral", "Token Generation Event"],
  //     datasets: [
  //       {
  //         data: [7,7,5,2,2,2,75],
  //         backgroundColor: ["#003380","#0052d0","#196EF0","#26A4F3","#54bfff","#A9E5FF","#FF5959"],
  //         borderWidth: 0
  //       }
  //     ]
  //   },
  //   options: {
  //     title: {
  //       display: false
  //     },
  //     legend: {
  //       display: false
  //     },
  //     tooltips: {
  //       enabled: false
  //     },
  //     layout: {
  //       padding: {
  //         left: 5,
  //         right: 5,
  //         top: 5,
  //         bottom: 10
  //       }
  //     }
  //   }
  // });

  // var ctx_chart_shadow = document.getElementById("economy-chart-shadow").getContext("2d");
  // var economyChartShadow = new Chart(ctx_chart_shadow,{
  //   type: 'bluredDoughnut',
  //   data: {
  //     labels: ["Early investors of platform", "Team", "Advisers", "Early partners", "Bounty", "Referral", "Token Generation Event"],
  //     datasets: [
  //       {
  //         data: [7,7,5,2,2,2,75],
  //         backgroundColor: ["#003380","#0052d0","#196EF0","#26A4F3","#54bfff","#A9E5FF","#FF5959"],
  //         borderWidth: 0
  //       }
  //     ]
  //   },
  //   options: {
  //     title: {
  //       display: false
  //     },
  //     legend: {
  //       display: false
  //     },
  //     tooltips: {
  //       enabled: false
  //     },
  //     layout: {
  //       padding: {
  //         left: 5,
  //         right: 5,
  //         top: 5,
  //         bottom: 10
  //       }
  //     }
  //   }
  // });

  $('.roadmap').not('.slick-initialized').slick({
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    dots: false,
    infinite: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      },
      {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  $('.panel-collapse').on('show.bs.collapse', function () {
    $(this).parents('.panel').addClass('active');
  }).on('hide.bs.collapse', function () {
    $(this).parents('.panel').removeClass('active');
  });

  function newAlert(text, html_class){
    html_class = (html_class != '')? 'alert-' + html_class : '';
    return '<div class="alert ' + html_class + ' fade in">' +
    '<button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="icon-cancel"></i></button>' +
    text +
    '</div>';
  }

  function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);
    return pattern.test(emailAddress);
  }

  $('#form-subscribe').submit(function(e){
    e.preventDefault();
    var form = $(this);
    var email = $.trim(form.find('input[name="email"]').val());
    var terms = form.find('input[name="terms"]:checked');

    if (!email || !isValidEmailAddress(email)){
      $('.alerts-wrapper').html(newAlert('<p>Please enter a valid email address</p>', 'danger'));
      return false;
    }

    if(terms.length == 0){
      $('.alerts-wrapper').html(newAlert('<p>Please indicate that you agree with Terms & Conditions</p>', 'danger'));
      return false;
    }

    $.ajax({
      url: "https://app.getresponse.com/add_subscriber.html",
      data: {
        "email": email,
        "campaign_token": 'n2uVQ'
      },
      type: "POST",
      dataType: "json",
      crossDomain: true,
      statusCode: {
        0: function() {
          form.find('input[name="email"]').val('');
          $('.alerts-wrapper').html(newAlert('<p>Thank you, we received your information</p>', 'info'));
        },
        200: function() {
          form.find('input[name="email"]').val('');
          $('.alerts-wrapper').html(newAlert('<p>Thank you, we received your information</p>', 'info'));
        }
      }
    });
  });

});